<template>
  <template v-if="layout">
    <component :is="layout" v-slot="slotProps">
      <router-view v-bind="slotProps" :key="$route.fullPath" />
    </component>
  </template>
  <router-view v-else :key="$route.fullPath" />
  <SubscribeTgChannel ref="subscribeTgChannel" />
</template>

<script setup>
import {
  computed, watch, ref,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useAppStore from '@/store/app';
import useAuthStore from '@/store/auth';
import useAccountStore from '@/store/account';
import SubscribeTgChannel from '@/components/modals/SubscribeTgChannel.vue';

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const accountStore = useAccountStore();

const layout = computed(() => route.meta.layout);
useAppStore().storeUtm();

watch(() => authStore.token, () => {
  if (!authStore.token) {
    router.push({
      name: 'auth',
      query: {
        url: route.fullPath,
      },
    });
  }
});

watch(() => authStore.user?.isAdmin, (newValue) => {
  if (route.meta?.requireAdmin === true && !newValue) {
    router.push({
      name: 'access-denied',
      query: {
        url: route.fullPath,
      },
    });
  }
});

const subscribeTgChannel = ref(null);
watch(() => accountStore.account?.id, async (newValue) => {
  if (!newValue || accountStore.account.planId !== 1) {
    return;
  }
  const isSubscriber = await accountStore.subscriberStatus();
  if (isSubscriber) {
    return;
  }
  subscribeTgChannel.value?.show();
}, { immediate: true });
</script>
