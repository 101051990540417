import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import api, { setAuthStore as apiSetAuthStore } from '@/utils/api';

const store = defineStore('auth', () => {
  const token = ref(null);
  const user = ref(null);

  const loadUser = async () => {
    if (token.value === null) {
      user.value = null;
      return;
    }
    user.value = await api.get('auth/user');
  };

  const updateToken = async (newToken) => {
    token.value = newToken;
    if (process.env.NODE_ENV !== 'production') {
      localStorage.setItem('auth_token', newToken);
    }
    await loadUser();
  };

  const clearToken = () => {
    updateToken(null);
  };

  const login = async (email, password) => {
    await updateToken(await api.post('auth/login', { email, password }));
  };

  const loginByGoogle = async (accessToken, params) => {
    await updateToken(await api.post('auth/google/login', { token: accessToken, ...params }));
  };

  const loginByTelegram = async (authData, params) => {
    await updateToken(await api.post('auth/telegram/login', { authData, ...params }));
  };

  const loginByTelegramApp = async (webAppData, params) => {
    await updateToken(await api.post('auth/telegram/webapp', { webAppData, ...params }));
  };

  const connectTelegram = async (currentPassword, authData) => {
    await api.post('auth/telegram/connect', { currentPassword, authData });
    loadUser().then();
  };

  const loginByVekrosta = async (jwtToken, params) => {
    await updateToken(await api.post('auth/vekrosta/login', { token: jwtToken, ...params }));
  };

  const connectVekrosta = async (currentPassword, jwtToken) => {
    await api.post('auth/vekrosta/connect', { currentPassword, token: jwtToken });
    loadUser().then();
  };

  const logout = async () => {
    await api.post('auth/logout');
    clearToken();
  };

  const join = async (email, password, code, challenge) => {
    await updateToken(await api.post('auth/join/confirm', {
      email, password, code, challenge,
    }));
  };

  const resign = async (currentPassword) => {
    await api.post('auth/resign', { currentPassword });
    clearToken();
  };

  const resetPassword = async (email, password, code) => {
    await updateToken(await api.post('auth/password/reset-confirm', { email, password, code }));
  };

  const changePassword = async (password, currentPassword) => {
    await updateToken(await api.post('auth/password/change', { password, currentPassword }));
  };

  const confirmEmail = async (email, code) => {
    await updateToken(await api.post('auth/email/change-confirm', { email, code }));
  };

  const refreshToken = async () => {
    if (process.env.NODE_ENV !== 'production') {
      token.value = localStorage.getItem('auth_token');
    }
    try {
      await updateToken(await api.get('auth/token'));
    } catch (e) {
      clearToken();
    }
  };

  const levels = computed(() => {
    if (!user.value) {
      return [];
    }
    return user.value.isPremium ? ['member', 'premium'] : ['member'];
  });

  return {
    token,
    user,
    clearToken,
    login,
    loginByGoogle,
    loginByTelegram,
    loginByTelegramApp,
    connectTelegram,
    loginByVekrosta,
    connectVekrosta,
    logout,
    join,
    resign,
    resetPassword,
    refreshToken,
    changePassword,
    confirmEmail,
    levels,
  };
});

apiSetAuthStore(store);

export default store;
